// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\wamp64\\www\\repo-github-admin-dashboards\\dashboards\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-list-template-js": () => import("C:\\wamp64\\www\\repo-github-admin-dashboards\\dashboards\\src\\templates\\blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-post-js": () => import("C:\\wamp64\\www\\repo-github-admin-dashboards\\dashboards\\src\\templates\\blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("C:\\wamp64\\www\\repo-github-admin-dashboards\\dashboards\\src\\templates\\page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-tags-js": () => import("C:\\wamp64\\www\\repo-github-admin-dashboards\\dashboards\\src\\templates\\tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("C:\\wamp64\\www\\repo-github-admin-dashboards\\dashboards\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}


module.exports = [{
      plugin: require('C:/wamp64/www/repo-github-admin-dashboards/dashboards/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"C:\\wamp64\\www\\repo-github-admin-dashboards\\dashboards\\src\\templates\\page.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":590,"linkImagesToOriginal":false,"withWebp":true}},{"resolve":"gatsby-remark-prismjs"},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-autolink-headers"}]},
    },{
      plugin: require('C:/wamp64/www/repo-github-admin-dashboards/dashboards/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/wamp64/www/repo-github-admin-dashboards/dashboards/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/wamp64/www/repo-github-admin-dashboards/dashboards/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-142546959-1"},
    }]
